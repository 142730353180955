<template>
    <v-container id="what-we-do">
        <v-row justify="center" class="text-center">
            <v-col xs="4" sm="4" md="2">
                <v-icon>mdi-cloud-outline</v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="text-center" xs="12" sm="12" md="12">
                <h2 id="title">What We Do</h2> 
            </v-col>
        </v-row>

        <v-row class="service-items">
            <v-col xs="12" sm="4" md="4">
                <!-- Icon for Software Development -->
                <v-icon large class="mb-3">mdi-code-tags</v-icon>
                <h3 class="text-xl font-bold mb-4">Software Development</h3>
                <p>Custom software solutions that match your unique business needs, helping you enhance efficiency and growth.</p>
            </v-col>

            <v-col xs="12" sm="4" md="4">
                <!-- Icon for Consultancy -->
                <v-icon large class="mb-3">mdi-lightbulb-on-outline</v-icon>
                <h3 class="text-xl font-bold mb-4">Consultancy</h3>
                <p>Expert advice to optimize your technology infrastructure, integrate new tools, and improve your workflows.</p>
            </v-col>

            <v-col xs="12" sm="4" md="4">
                <!-- Icon for Integration Services -->
                <v-icon large class="mb-3">mdi-link-variant</v-icon>
                <h3 class="text-xl font-bold mb-4">Integration Services</h3>
                <p>Seamless integration of third-party systems, ensuring all your tools work together harmoniously to maximize efficiency.</p>
            </v-col>
        </v-row>

        <v-row class="service-items">
            <v-col xs="12" sm="12" md="12">
                <p>
                    At <span>3Bit Machine</span>, we take pride in offering tailored solutions that address your unique business challenges. Whether you need custom software development, expert consultancy, or seamless integration of third-party systems, we are here to deliver results that drive growth and efficiency. 
                </p>
                <p class="mb-16">
                    With a dedicated team of professionals and a proven process, we ensure your projects are handled with precision and care from start to finish. Let's turn your ideas into reality and help your business reach its full potential.
                </p>
                <h3 class="mt-4">Ready to start your project?</h3>
                <p>
                    Get in touch with us today to discuss how we can collaborate and create solutions that work for you.
                </p>

                <div class="text-center">
                    <v-icon large class="my-4">mdi-arrow-down</v-icon>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
export default {
    name:"services",
}
</script>

<style lang="scss" scoped >
    $small: 300px;
    $medium: 900px;

   #what-we-do {
       margin: auto;
       height:auto;
       background-color : white;

       #title{
            text-transform: uppercase;
            font-weight: bold;
            padding-top:15px;
            font-size: 2.5em;
       }

       .service-items {
           
           margin-inline: 0px;
           p{  
               font-family: var(--secondary-font);
               font-size:1em;
               color:var(--main-bg-color);
               transition: font-size 0.3s ease-in-out;
           }
           span {
            color: var(--highlight-color);
            font-weight: bold;
           }
       }

   }
</style>