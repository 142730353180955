<template>
    <v-container fluid class='homepage ma-0 pa-0' app>
        <navigation id='nav'></navigation>

        <slider/>
        <about/>
        <services/>
        <contact></contact>
        <site-footer></site-footer>
    </v-container>
</template>

<script>
    import slider from "../components/slider.vue"
    import services from "../components/services.vue"
    import about from "../components/about.vue"
    import contact from '../components/contact.vue'
    import siteFooter from '../components/footer.vue'
    import navigation from '../components/navigation.vue'

    export default { 
        name: 'homepage',components: {
            navigation,
            slider,
            services,
            about,
            contact,
            siteFooter,
        } 
    }  
      
</script>

<style scoped >
#nav{
    position: fixed;
    z-index: 4;
}


</style>
