<template>
    <div class="slider d-flex flex-column justify-center align-items-center  ">
          
          <div class="intro">
            <p class="ma-0 pa-0 threebit-white">Innovative Software Solutions</p>
            <h1 class="heading ma-0 pa-0 ">3Bit Machine</h1>
            <h2 class="threebit-white"><span>Empowering</span> businesses through <span>technology</span></h2>
          </div>
    </div>
</template>


<script>
export default {
    name:"slider"
}
</script>


<style lang="scss" >
     .slider {
        text-align: left;
        height:100vh;
        width:100vw;
        background-color: var(--main-bg-color);
        background-image: url(../assets/slide_3bit.jpg);
        background-size: cover;
        padding: 10%;
    }
    
    .intro{
       
        p {
            margin-left: 10px; 
            padding: 0;
            font-size: 1.5em ;
            font-weight: bold;
        };

        span{
            color : #E0E0E0;
            text-shadow:
                -1px -1px 0 #0070FF,
                1px -1px 0 #0070FF,
                -1px 1px 0 #0070FF,
                1px 1px 0 #0070FF;
        }

        .btn{
            color: var(--gray);
        }

      
        .secondary-text{
            color: var(--gray) ;
        }

        .heading {
            margin:0px; 
            padding: 0px;
            font-size: 4em ;
            color : #E0E0E0;
            text-shadow:
                -1px -1px 0 #0070FF,
                1px -1px 0 #0070FF,
                -1px 1px 0 #0070FF,
                1px 1px 0 #0070FF;
        }

    }


</style>