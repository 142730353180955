<template>
    <v-container fluid class='contact pa-2' id='contact'>
        <v-container>
        <v-row class="py-8">

            <v-col class="info-text" xs="12"  sm="12" md="6">
                 <h2 >
                     Let's 
                 </h2>
                 <h2>
                     Connect
                     <v-icon>
                        mdi-arrow-right
                     </v-icon>
                 </h2>
            </v-col>
           
            <v-col class="info-text"  xs="12"  sm="12" md="6" >
                <p>
                    Got a question or want to work with us?<br /> Email us at info@3bitmachine.com.
                </p>

                <p>
                    We’d love to hear from you!
                </p>
            </v-col>
        </v-row>
        </v-container>
    </v-container>
</template>

<script>
    export default { 
        name: 'contact',
        components: {

        },      
    }    
        </script>

<style scoped lang="scss">

#contact{
    background-color:var( --main-bg-color);
}

.info-text{
    h2 { 
        max-width:600px;
        width:100vw;  
        font-size: 3em;
        color:var(--highlight-color);
    }
    .mdi-arrow-right {
        font-size: 1.5em;
        color:var(--dark-accent);
    }

    p {
        color:var(--gray);
        font-weight: bold;
        font-size: 1.2em;
    }
}

</style>
