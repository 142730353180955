<template>
    <section id="who-we-are" class="bg-white py-16">
        <div class="container mx-auto px-4">
            <h2 class="mb-6">At 3Bit Machine, we innovate and simplify software development and integration processes, empowering businesses to work more efficiently. Our mission is to deliver creative, streamlined solutions that drive growth and reduce complexity for our clients.</h2>

            <p class="text-lg">We specialize in providing top-tier <strong>software development</strong>, <strong>consultancy</strong>, and <strong>integration services</strong> to help businesses achieve their technical goals effortlessly.</p>
        </div>

        <div class="container mx-auto px-4">
            <h2 id="title" class="pb-8">Our Values</h2>
            <v-row>
                <v-col xs="12" sm="4" md="4">
                    <h3>Innovation</h3>
                    <p>We stay ahead of the curve, always embracing new technologies to deliver cutting-edge solutions.</p>
                </v-col>
                <v-col xs="12" sm="4" md="4">
                    <h3>Collaboration</h3>
                    <p>We work closely with our clients, ensuring every solution meets your unique business needs.</p>
                </v-col>
                <v-col xs="12" sm="4" md="4">
                    <h3>Customer-Centric</h3>
                    <p>Your success is our priority, and we’re committed to building long-term relationships.</p>
                </v-col>
            </v-row>
        </div>
    </section>
</template>

<script>
export default {
    name : 'about'
}
</script>


<style lang="scss" scoped > 
    #who-we-are{

       h2 {  
           font-size: 1.5em;
           padding-top:15px;
        }
        p {
             padding-block: 10px;
             padding-right: 10px;
             font-weight: 400;
             color: var(--gray);
             font-size: 1em;
        }
        .text-lg {
            font-size: 1.5em;
        }
    }

</style>